main{
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 300px;
  padding-bottom: 160px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  height: 100%;
}

main:after {
  content: '';
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgb(0,0,0);
  background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
  z-index: 0;
}

main:before {
  content: '';
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-image: url(".//blackh.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.row{
  text-align: center;
  z-index: 1;
}
.row label{
  display: block;
  color: #d69b2e;
  font: normal normal 300 15px/16px Montserrat;
  margin-top: 16px;
}

h3{
  color: #fff;
  font: normal normal 600 18px/19px Montserrat;
  text-transform: uppercase;
  display: block;
  margin-bottom: 12px;
}

.btn {
  color: #444;
  border-radius: 5px;
  padding: 10px 25px;
  font: normal normal 600 25px/26px Montserrat;
  text-shadow: 0px -1px 0px rgba(0,0,0,0.4);
  text-decoration: none;
  background: transparent;
  cursor: pointer;
  position: relative;
  display: inline-block;
  box-shadow: inset 0px 1px 0px rgba(255,255,255,1),0px 1px 3px rgba(0,0,0,0.3);
  outline: none;
text-transform: uppercase;
}
.gold-btn{
  border: 1px solid #d69b2e;
  background: -webkit-gradient(linear, left top, left bottom, from(#d69b2e), to(#fea));
}

.white-btn{
  font: normal normal 600 14px/16px Montserrat;
  box-shadow: inset 0px 1px 0px rgba(255,255,255,.5),0px 1px 3px rgba(0,0,0,0.3);
  border-color: #111;
  color: #aaa;
  text-shadow: -1px -1px 1px rgba(0,0,0,1);
  background: -webkit-gradient(linear, left top, left bottom, from(#000), to(#333));
}
.btn:active{
  -webkit-transform: translateY(2px);
  transform: translateY(2px);
}

.counter{
  margin-left: 15px;
  display: inline-block;
  position: relative;
  top: 7px;
}

.counter .action{
  color: #fff;
  font: normal normal 300 30px/31px Montserrat;
  display: inline-block;
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
  border-radius: 15px;
  cursor: pointer;
}

.counter .action:hover {
  background-color: rgba(255,255,255, 0.8);
  color: #000;
}

.counter .action.up > span{
  position: relative;
  top: -4px;
}

.counter .action.down > span{
  position: relative;
  top: -6px;
}

.counter .value{
  display: inline-block;
  margin: 0 16px;
  color: #d69b2e;
  font: normal normal 600 45px/46px Montserrat;
  position: relative;
  top: 2px;
  min-width: 62px;
}

.sep{
  font: normal normal 300 14px/23px Montserrat;
  margin: 24px 0;
  text-transform: uppercase;
}

header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  height: 240px;
  width: 100%;
  background-image: url("./logo.png");
  background-size: 220px auto;
  background-position: top center;
  background-repeat: no-repeat;
  color: #d69b2e;
}

header h1{
  color: #fff;
  display: block;
  text-align: center;
  margin-top: 150px;
  font: normal normal 600 45px/46px Montserrat;
}
header h2{
  display: block;
  text-align: center;
  font: normal normal 300 20px/21px Montserrat;
  font-style: italic;
}

footer{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100px;
  width: 100%;
  background-image: url("./logo.png");
  background-size: 75px auto;
  background-position: center center;
  background-repeat: no-repeat;
}


.copyright {
  font: normal normal 300 14px/15px Montserrat;
  letter-spacing: 0px;
  color: #BDA371;
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}

.error{
  font: normal normal 300 16px/26px Montserrat;
}

.footer-logo {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 58px);
  width: 116px;
  height: 80px;
}

@media screen and (max-width: 1080px) {
  footer {
    padding-bottom: 120px;
  }

  footer p {
    padding: 0 24px;
  }

  .copyright {
    width: 100%;
    left: 0;
    right: inherit;
    text-align: center;
    bottom: 10px;
  }

  .footer-logo {
    bottom: 30px;
  }

}
