.text-white{
    color:white;
}

.gold-input {
  border: 1px solid #d69b2e;
  background: -webkit-gradient(linear, left top, left bottom, from(#d69b2e), to(#fea));
  text-align: center;
  z-index: 999;
}
main{
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 300px;
    padding-bottom: 160px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    height: 100%;
  }
  
  main:after {
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0,0,0);
    background: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 60%);
    z-index: 0;
  }
  
  main:before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-image: url(".//blackh.gif");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
  }

  .error-text{
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  